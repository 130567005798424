import React, { useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { Head } from './Head';
import { PlayerMain } from './PlayerMain';
import { Layout } from './Layout';
import { Context } from '../contextSubtitles';

import PlayerScrubbr from './PlayerScrubbr';
import { PlayerTop } from './PlayerTop';
import { Preloader } from './Preloader';
import { Start } from './Start';
import { PlayerChapters } from './PlayerChapters';
import { PlayerVideo } from './PlayerVideo';
import { Subtitles } from './Subtitles';
import { PlayerVideoSimple } from './PlayerVideoSimple';
import { Footer } from './Footer';
import { TrackSubtitles } from './TrackSubtitles';
import { SubtitlesPlayer } from './Subtitles/SubtitlesPlayer';

export function LayoutSubtitles() {
  const context = useContext(Context);

  const { state, handles } = context;

  useEffect(() => {

    handles.setRussian(true);
    handles.setSubtitleMode(true);

  }, []);

  // const { mediaId } = useParams();
  const location = useLocation();




  return (
    <Layout context={context}>


      {Boolean(context.state['mp3']) && <SubtitlesPlayer
        context={context}
      >
        <Subtitles context={context} />
      </SubtitlesPlayer>}

      {/* <Start /> */}

      <Head context={context} languageShown={true} />

      {!context.state['mp3'] && <Preloader />}

      {/* <PlayerTop>
          <PlayerScrubbr>
          <PlayerChapters />
          </PlayerScrubbr>
        </PlayerTop> */}

      {/* {Boolean(context.state['mp3']) &&
        context.state.videoPlayerType === 'simple' &&
        <PlayerVideoSimple context={context}>
          <Subtitles context={context} />
        </PlayerVideoSimple>} */}


      {/* {Boolean(context.state['mp3']) &&
        context.state.videoPlayerType === 'advanced' &&
        <PlayerVideo context={context}>
          <Subtitles context={context} />
        </PlayerVideo>} */}



      {Boolean(context.state['mp3']) && <>
        <TrackSubtitles
          subtitleMode={true}
          subtitles={true}
        />
      </>}


      <Footer context={context} view={['zoomLevel', 'speed']} />

    </Layout>
  )
}
