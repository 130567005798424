import React, { useState, useContext } from "react";
import Draggable from "react-draggable";
import { TooltipH } from "../UiKit/TooltipH"
import { PlayerVideo } from '../PlayerVideo';
import { Context } from '../../contextSubtitles';

export function SubtitlesPlayer() {
  const context = useContext(Context);
  const [left, setLeft] = useState(true);
  const expanded = context.state.video;

  return (
    <Draggable>
      <div className={`x_sub_player0 ${expanded ? '' : 'x_sub_player0--collapsed'} ${left ? '' : 'x_sub_player0--right'}`}>
        <div className="x_sub_player_h">
          <div className="p_b_title">
            <div className="ico">
              <div>videocam</div>
            </div>
            <div>Видео</div>
          </div>
          <div className="x_sub_player_right">

            {expanded && <TooltipH title={`Переместить ${left ? 'вправо' : 'влево'}`}>
              <div className="x_button x_button--ghost"
                onClick={() => setLeft(prev => !prev)}
              >
                <div className="t_icon">
                  {left ? 'arrow_forward' : 'arrow_back'}
                </div>
              </div>
            </TooltipH>}

            {expanded && <div className="x_button x_button--ghost"
              onClick={() => context.handles.setVideo()}
            >
              <div>Скрыть</div>
            </div>}

            {!expanded && <div className="x_button x_button--ghost"
              onClick={() => context.handles.setVideo("sm")}
            >
              <div>Развернуть</div>
            </div>}


          </div>
        </div>
        <div className="x_sub_player _fv _fcc">
          <PlayerVideo context={context}><></>
          </PlayerVideo>
        </div>
      </div>
    </Draggable>
  )
}
