import React, { useState, useContext, useCallback, MouseEvent } from 'react';
import { Select } from 'antd';
import { Track } from 'types';
import { Ico } from './Uikit';
import { Context } from '../context';
import { PreloaderGif } from './Preloader';
import { formatTime } from './utils';
import { PopoverH } from './UiKit/PopoverH';
import { ButtonH } from './UiKit/ButtonH';
import { ContentEditable } from './elements/ContentEditable';
import { PlayerLine } from './PlayerLine';

interface TranslateLineProps {
  track: Track;
  editing: string;
  setEditing: (editing: string) => void;
}

export function TranslateLine({ track, editing, setEditing }: TranslateLineProps) {
  const { state, handles } = useContext(Context);
  const [voice, setVoice] = useState('male1');

  const [captions, setCaptions] = useState(track.captions);
  const [captionsRus, setCaptionsRus] = useState(track.captions_rus);

  const resetCaptions = () => {
    setCaptions(track.captions)
  }

  const saveCaptions = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    handles.saveCaptionsTranslate(track, captions);
    setEditing('');
  }

  // hanlde input only when the element is not focused.
  // It happens when the value is changed from find and replace addon
  const handleInput = (text: string, focused: boolean) => {
    if (!focused) {
      handles.saveCaptionsTranslate(track, text);
      setEditing('');
    }
  }

  const handleResetAndToggle = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    if (typeof state.playStart === 'number' && state.playingFragment === track.id) {
      // wavesurfer.seekTo(0);
    }
  };

  const handleGenerate = useCallback(async () => {
    console.log('track = ', track);


    try {
      const data = await handles.audioSynthesize(track.original_id, state.folder, state.mediaId);
      // Handle the response data as needed
    } catch (error) {
      // Handle the error (e.g., show an error message to the user)
    }
  }, [track.original_id, state.folder, state.mediaId, handles.audioSynthesize]);

  return (
    <div
      onClick={() => setEditing(track.id)}
      className={`xt_line ${editing === track.id ? 'active' : ''}`}
    >
      <div className="xt_time">

        <div className="xt_fragment_play0" onClick={handleResetAndToggle}>
          <div className="x_player_control_play" onClick={(e) => {
            handles.setTranslateLineOffset(track['start'] + e.pageY);
            setEditing(track.id)
            if (state['mp3']) {

              // handles.setPlaybackTime(track['start'])
              handles.setPlayStart(track['start'])
              // handles.setPlayOriginalStart(track['original_start']);
              if (state['playing'] && state.playingFragment === track.id) {
                handles.setPlaying(false)
                handles.setPlayingFragment()
              }

              if (!state['playing'] || state.playingFragment !== track.id) {
                handles.setPlaying(true)
                handles.setPlayingFragment(track.id)
              // handles.setPlaybackTime(track['start'])
              // handles.setPlayOriginalStart(track['original_start']);
              }
              // handles.setPlaying(!state['playing'])
            }
          }
          }>
            {state['mp3'] && <div className="ico ico--40">
              {state.playingFragment === track.id && <>
                {!state['playing'] && <div>play_arrow</div>}
                {state['playing'] && <div>pause</div>}
              </>}

              {state.playingFragment !== track.id && <>
                <div>play_arrow</div>
              </>}


            </div>}
            {!state['mp3'] && <PreloaderGif />}
          </div>
        </div>

        <div className="xt_time_1">{formatTime(track.start_rus)}</div>
        <div className="xt_time_1">–</div>
        <div className="xt_time_1">{formatTime(track.end_rus)}</div>
      </div>

      <div className="xt_text p-4">{captionsRus}</div>

      <div className={`xt_text ${state.changedRecently.includes(track.id) ? 'xt_text--justchanged' : ''} ${state.changedTranslation.includes(track.id) ? 'xt_text--changed' : ''}`}>
        {<ContentEditable className="p-4"
          captions={captions}
          handleInput={handleInput}
          handleBlur={(text) => setCaptions(text)}>
        </ContentEditable>}
        {editing === track.id && <div className="xt_textarea_actions">
          <div className="x_save  x_save--cancel" onClick={resetCaptions}><Ico>undo</Ico></div>
          <div className="x_save" onClick={saveCaptions}><Ico>check</Ico></div>
        </div>}
      </div>

      <div className="xt_trans">
        {!track.file && <div className="xt_fragment_play0">
          <PopoverH
            placement='bottomRight'
            trigger="click"
            title={<>
              <div className="_fv" style={{ padding: '1em' }}>
                <b>Generate translation</b>
                <div
                // onClick={(e) => e.stopPropagation()}
                >
                  <div className="_fm">
                    <Select
                      value={voice}
                      onChange={(value) => {
                        console.log('value = ', value)
                        setVoice(value)
                      }}
                      style={{ width: '180px' }}
                      options={[{
                        value: 'male1',
                        label: 'Олег Торсунов',
                      },
                      {
                        value: 'male2',
                        label: 'Аудитория: Мужской',
                      },
                      {
                        value: 'female',
                        label: 'Аудитория: Женский',
                      },
                      ]}
                    /></div>
                </div>
                <ButtonH onClick={handleGenerate}>Сгенерировать перевод</ButtonH>
              </div>
            </>}>
            <div className="x_player_control_play">
              <div className="ico ico--40"><div>maps_ugc</div></div>
            </div>
          </PopoverH>
        </div>}
        {track.file && <div className="xt_fragment_play0">
          <div className="x_player_control_play">
            <div className="ico ico--40"><div>campaign</div></div>
          </div>
        </div>}
      </div>

      <PlayerLine track={track} />

      {editing === track.id && <div className="xt_line_bg active" />}
    </div>
  );
}
